import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Postbox } from "../postbox/postbox";
export class DeleteAppointmentDialogViewModel {
  modalTitle = ko.observable("");
  modalKeys = ko.observableArray([]);
  modalColumns = ko.observableArray([]);
  modalData = ko.observableArray([]);
  modalHandleSelection = ko.observable();
  givenId = "DeleteAppointmentDialogExt";
  AppointmentId = ko.observable();
  isCompletion = ko.observable(true);
  checkComplete = ko.observable<boolean>(false);
  ReasonForDeletion = ko.observable<string>("");
  showError = ko.observable<boolean>(false);
  deletionDialog = ko.observable<boolean>(false);

  async DeleteAppointment() {
    if (ko.toJS(this.ReasonForDeletion) === "") {
      this.showError(true);
      $("#deletionReasonContainer_inp").addClass("has-error");
      return;
    } else {
      $("#deletionReasonContainer_inp").removeClass("has-error");
    }
    await RNSAPI.deleteAppointment(
      ko.toJS(this.AppointmentId()),
      ko.toJS(this.ReasonForDeletion)
    ).then((response) => {
      Postbox.publisher().publish(true, "updateTFW");
    });

    this.clearModal();
  }

  clearModal(): void {
    $("#DeleteAppointmentDialogExt").modal("hide");

    this.AppointmentId("");
    this.isCompletion(true);
    this.checkComplete(false);
    this.showError(false);
  }

  constructor() {
    Postbox.publisher().subscribe((appointmentData) => {
      $("#DeleteAppointmentDialogExt").modal("show");
      this.AppointmentId(appointmentData.Id);
      this.isCompletion(ko.toJS(appointmentData.Complete));
      this.ReasonForDeletion("");
      $("#deletionReasonContainer_inp").removeClass("has-error");
    }, "DeleteAppointmentModalData");
  }
}

let html = fs.readFileSync(__dirname + "/DeleteAppointmentDialog.html", "utf8");

ko.components.register("delete-appointment-dialog-view", {
  viewModel: DeleteAppointmentDialogViewModel,
  template: html,
});
