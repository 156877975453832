import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Postbox } from "../postbox/postbox";

export class CompleteDeadlineDialogViewModel {
  modalTitle = ko.observable("");
  modalKeys = ko.observableArray([]);
  modalColumns = ko.observableArray([]);
  modalData = ko.observableArray([]);
  modalHandleSelection = ko.observable();
  givenId = "VerificationFrist_Body";

  deadlineReason = ko.observable<string>("");
  deadlineId = ko.observable();
  deadlineCaseId = ko.observable<string>("");
  checkComplete = ko.observable<boolean>(false);
  completionReason = ko.observable<string>("");
  IsVerifcation = ko.observable<boolean>(false);
  verificationCompletionReason = ko.observable<string>("");
  ReasonForRejection = ko.observable<string>("");
  isRejectingModal = ko.observable<boolean>(false);
  showError = ko.observable<boolean>(false);

  async openCompleteDeadlineModal(deadlineData) {
    $("#rejectVerificationFristCompletion_Body").addClass("hide");
    $("#VerificationFrist_Body").modal("show");

    this.deadlineCaseId(deadlineData.CaseId);
    this.deadlineReason(deadlineData.Reason);
    this.deadlineId(deadlineData.Id);
    this.IsVerifcation(deadlineData.IsVerifcation);
    this.verificationCompletionReason(deadlineData.ReasonForCompletion);
  }

  async completeDeadline() {
    if (
      ko.toJS(this.completionReason) === "" &&
      ko.toJS(this.IsVerifcation) === false
    ) {
      $("#completionReasonContainer").addClass("has-error");
      return;
    } else $("#completionReasonContainer").removeClass("has-error");

    this.checkComplete(true);
    if (
      ko.toJS(this.completionReason) === "" &&
      ko.toJS(this.IsVerifcation) === true
    ) {
      this.deadlineId().ReasonForCompletion = ko.toJS(
        this.verificationCompletionReason
      );
      this.deadlineId().CompletedByVerification = ko.toJS(
        this.deadlineId().VerifiedByFormated
      );
    } else
      this.deadlineId().ReasonForCompletion = ko.toJS(this.completionReason);
    await RNSAPI.completeDeadline(this.deadlineId()).then((response) => {
      Postbox.publisher().publish(true, "updateTFW");
      this.clearModal();
    });
  }

  async rejectCompletion() {
    if (ko.toJS(this.ReasonForRejection) === "") {
      $("#compleationReasonContainer").addClass("has-error");
      $("#reasonForRejection_inp").addClass("has-error");
      return;
    } else $("#compleationReasonContainer").removeClass("has-error");
    this.deadlineId().ReasonForRejection = ko.toJS(this.ReasonForRejection);
    await RNSAPI.rejectCompletion(this.deadlineId()).then((response) => {
      Postbox.publisher().publish(true, "updateTFW");
      this.clearModal();
    });
  }

  async rejectDeadlineCompletion() {
    this.isRejectingModal(true);
    this.IsVerifcation(false);
    this.showError(false);
    $("#reasonForRejection_inp").removeClass("has-error");
    $("#VerificationFrist_Body").addClass("hide");
    $("#VerificationFrist_Body").removeClass("show");
    $("#rejectVerificationFristCompletion_Body").addClass("show");
    $("#rejectVerificationFristCompletion_Body").removeClass("hide");
  }

  clearModal(): void {
    $("#VerificationFrist_Body").modal("hide");
    $("#rejectVerificationFristCompletion_Body").modal("hide");
    this.deadlineId("");
    this.deadlineReason("");
    this.deadlineCaseId("");
    this.completionReason("");
    this.isRejectingModal(false);
    this.checkComplete(false);
  }
  constructor() {
    Postbox.publisher().subscribe((deadlineData) => {
      this.clearModal();
      this.openCompleteDeadlineModal(deadlineData);
    }, "CompleteDeadlineModalData");
  }
}

let html = fs.readFileSync(__dirname + "/CompleteDeadlineDialog.html", "utf8");

ko.components.register("complete-deadline-dialog-view", {
  viewModel: CompleteDeadlineDialogViewModel,
  template: html,
});
